<template>
    <main>
        
        <section class="section pt-3 demo">
            <div class="container">
                
                <div class="credential_link_recovery text-center">
                
                    <h2><strong>Recuperación de contraseña</strong></h2>
                    <p class="form__success">Escriba su correo electrónico y le enviaremos un enlace con el que se generará una nueva contraseña</p>
                    
                    <form class="form container container--xs form-login" @submit.prevent="submitForm(form)">
                        
                        <div class="form__control text-left">
                            <label class="form__label"><strong>E-mail o Número telefónico:</strong></label>
                            <input type="text" class="form__field" placeholder="Ingresa con tu email o número telefónico" v-model="form.email">
                        </div>                        
                                                
                        <p class="form__alert" v-if="formErrors.errors.email">
                            {{ formErrors.errors.email[0] }}
                        </p>
                        <button type="submit" class="cta pt-1">Enviar</button>
                        

                    </form>
                </div>    
                <!-- hahshajjasjkkjasj -->
            </div>
        </section>
        
    </main>
</template>

<script>

import { ref } from "vue";
import API from "@/api";
import router from '@/router/index';
import store from '@/store';
import Swal from 'sweetalert2'
import { mapActions, mapState } from "vuex";


export default {
    name: 'CredentialLinkRecovery',
    components: {
      
    },
    setup(){
        const form = ref({ 
            email: "",            
        });                
        const formErrors = ref({errors:{}})        

        const submitForm = (form) => {            
            API.post("/api/link-recovery-password", form)
                .then(response => {
                    form.email = ""                    
                    Swal.fire({
                        icon: 'success',
                        title: 'Enlace de recuperación enviado a su email o número telefónico',
                        //text: 'Bono especia',                        
                    })
                    

                })
                .catch(error => {
                    console.log(error)                    
                    formErrors.value = error.response.data
                })
        }
        
        return {form, formErrors, submitForm }
    },

}
</script>
